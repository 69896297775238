import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, TableContainer, Table, Tbody, Td, Tr, Divider, Image, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { ICNDListData, IFilterListCertificate } from "../../../../_services/interface/cnd.interface";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { filterCertificatesByPropertyAndValue, getCertificatesPropertyValuesRecursively } from "../../Certificate/Util/CertificateUtils";
import { ViewPdfModal } from "./ViewPdfModal";
import { listCnds } from "../../../../_services/cnd.service";
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { statusForTagList } from "../util/cndUtils";
import { IColumn, IData, ITableMetaData } from "../../../../components/Table/table.interface";
import { Datatables } from "../../../../components/Table/Datatables";


interface IListAllCNDCustomFilterModalProps {
  currentClient: IClientDataResponse;
  certificates: ICertificate[];
  isOpen: boolean;
  onClose: () => void;
  data?: ICNDListData[];
  slug?: string;
  jurisdiction?: string;
  startDateFilter?: string;
  endDateFilter?: string;
}

export const ListAllCNdsCustomFilter = (props: IListAllCNDCustomFilterModalProps) => {
  const [cndData, setCNDData] = useState<IData<ICNDListData>|null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();
  const [currentFile, setCurrentFile] = useState<string>('');
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const toast = useToast();

  const fetchData = async () => {
    setLoadingState(true);
    let clone = props.data;
    if (!clone) { 
      const filters: IFilterListCertificate = {
        page: 1,
        row_by_page: -1,
      };

      if(props.startDateFilter) {
        filters.initial_created_at_date = props.startDateFilter;
      }

      if(props.endDateFilter) {
        filters.final_created_at_date = props.endDateFilter;
      }

      const { status, response } = await listCnds(props.currentClient.guid_client, filters);
      if (status === 200 && 'meta' in response && 'data' in response) {
        clone = [...response.data as ICNDListData[]];
      }
    }

    if (clone) {
      const products = props.currentClient.products ? props.currentClient.products : [];
      const slugsAvailable: string[] = [];
      props.certificates?.forEach((certificate) => {
        if (products[certificate.slug as string]) {
          const slugs = getCertificatesPropertyValuesRecursively([certificate], 'slug' as keyof ICertificate);
          slugs[0]?.forEach((slug: string) => {
            slugsAvailable.push(slug);
          });
        }
      }); 

      clone = clone.filter(item => { return slugsAvailable.includes(item.slug); });

      if (props.slug) {
        const certificates = filterCertificatesByPropertyAndValue(props.certificates, 'slug' as keyof ICertificate, props.slug);
        const slugs = getCertificatesPropertyValuesRecursively(certificates, 'slug' as keyof ICertificate);

        clone = clone.filter(item => { return slugs[0]?.includes(item.slug);  });
      }

      if (props.jurisdiction) {
        clone = clone.filter((x) => (x.jurisdiction === props.jurisdiction) || x.jurisdiction === props.jurisdiction);
      }

      const data: IData<ICNDListData> = {
        data: clone,
        meta: {
          currentPage: 1,
          isFirstPage: true,
          isLastPage: true,
          nextPage: null,
          pageCount: 1,
          previousPage: null,
          totalCount: clone.length,
        }
      }

      setCNDData(data);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar puxar as informações das certidões',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingState(false);
  }

  useEffect(() => {
    if (props.isOpen) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  useEffect(() => {
    sortData();
  }, [metaData]);

  const jurisdiction = (jurisdiction: any) => {
    if (jurisdiction === 'federal') {
      return (
        <Flex 
          color="#065666" 
          backgroundColor="#C4F1F9" 
          alignItems="center"
          justifyContent="center"
          p="2px 15px"
          fontSize="12px"
          fontFamily="Poppins-medium"
          lineHeight="16.8px"
          fontWeight="100"
          borderRadius={5}
          height="33px"
          width="fit-content"
          minWidth="auto"
        >
          Federal
        </Flex>
      );
    } else if (jurisdiction === 'state') {
      return (
        <Flex 
          color="#322659"
          backgroundColor="#E9D8FD"
          alignItems="center"
          justifyContent="center"
          p="2px 15px"
          fontSize="12px"
          fontFamily="Poppins-medium"
          lineHeight="16.8px"
          fontWeight="100"
          borderRadius={5}
          height="33px"
          width="fit-content"
          minWidth="auto"
        >
          Estadual
        </Flex>
      );
    } else if (jurisdiction === 'municipal') {
      return (
        <Flex 
          color="#134E4A"
          backgroundColor="#CCFBF1"
          alignItems="center"
          justifyContent="center"
          p="2px 15px"
          fontSize="12px"
          fontFamily="Poppins-medium"
          lineHeight="16.8px"
          fontWeight="100"
          borderRadius={5}
          height="33px"
          width="fit-content"
          minWidth="auto"
        >
          Municipal
        </Flex>
      );
    }
  }

  const getNameBySlug = (slug: string) => {
    const certificates = filterCertificatesByPropertyAndValue(props.certificates, 'slug' as keyof ICertificate, slug);
    return certificates[0]?.name;
  }

  const haveFileAvailable = (cnd: ICNDListData): boolean => {
    const status = cnd.status;

    if(cnd.guid_file && status && status.show_file && cnd.slug_status !== 'error') {
      return true;
    }

    return false;
  }

  const sortData = () => {
    if(!metaData || !cndData) return;
    
    const orderField = metaData.orderField as keyof ICNDListData;
    const orderDirection = metaData.orderDirection;
  
    const compareFunction = (a: ICNDListData, b: ICNDListData): number => {
      const aValue = a[orderField];
      const bValue = b[orderField];
  
      if (aValue === undefined || bValue === undefined || aValue === null || bValue === null) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }
  
      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };

    let data = cndData.data;
    data.sort(compareFunction);
    setCNDData({
      ...cndData,
      data
    });
  };

  const columns = [
    {
      title: 'Status',
      name: 'status.name',
      orderFieldName: 'slug_status',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: statusForTagList
      },
    },
    ...(!props.slug ? [{
      title: 'Certidão',
      name: 'certificate_name',
      sortable: true,
      align: 'center',
    }] : []),
    ...(!props.jurisdiction ? [{
      title: 'Jurisdição',
      name: 'jurisdiction',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Federal',
          value: 'federal',
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Municipal',
          value: 'municipal',
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
        {
          text: 'Estadual',
          value: 'state',
          textColor: '#322659',
          color: '#E9D8FD',
        },
      ],
    }] : []),
    {
      title: 'CNPJ/CPF',
      name: 'entity.documentFormatted',
      orderFieldName: 'company.cnpj,persona.document',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Emissão',
      name: 'issuedAtFormatted',
      orderFieldName: 'issuedAt',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Vencimento',
      name: 'validUntilFormatted',
      orderFieldName: 'validUntil',
      sortable: true,
      align: 'center',
    },
  ] as IColumn[];

  const actions = [
    {
      text: 'Visualizar arquivo',
      icon: <Image src="/icons/table/eye.svg" width={5} height="auto" />,
      isVisible: (cnd: ICNDListData): boolean => {return true},
      isDisabled: (cnd: ICNDListData): boolean => { return !haveFileAvailable(cnd) },
      action: (cnd: ICNDListData) => {
        setCurrentFile(cnd.file||'');
        setOpenFileModal(true);
      },
    },
  ]

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={props.isOpen} onClose={props.onClose} size="5xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">
            <Flex w="100%" alignItems="center" display="flex">
              <Flex>Certidões</Flex>
            </Flex> 
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>

              <TableContainer fontFamily="Poppins-Light" border="1px solid" borderColor="gray.300" borderRadius="5px">
                <Table variant='striped' colorScheme='gray' fontSize="12px" fontFamily="Poppins-Medium">
                  <Tbody>
                    {props.slug ? (
                      <Tr>
                        <Td textAlign="center" fontWeight="bold" w={4}>Certidão:</Td>
                        <Td><Flex w="120px;">{getNameBySlug(props.slug)}</Flex></Td>
                      </Tr>
                    ) : null}

                    {props.jurisdiction ? (
                      <Tr>
                        <Td textAlign="center" fontWeight="bold" w={4}>Jurisdição:</Td>
                        <Td><Flex w="120px;">{jurisdiction(props.jurisdiction)}</Flex></Td>
                      </Tr>
                    ) : null}
                  </Tbody>
                </Table>
              </TableContainer>

              <Divider />

              <Datatables<ICNDListData>
                name="Certidões"
                columns={columns}
                actions={actions}
                metaDataHook={setMetaData}
                data={cndData}
                isLoading={isLoading}
                pagination={false}
                rowByPage={false}
                heigth="400px"
              />

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <FormButton onClick={props.onClose} width="100%">Fechar</FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ViewPdfModal
        title="Visualizar Certidão" 
        file={currentFile} 
        openModal={openFileModal}
        openModalHook={setOpenFileModal}
      />
    </>
  );
}
